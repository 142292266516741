import styled from 'styled-components';
import { Text14Light } from '../../components/utils/Text/Text.styles';
import { Button } from '../../components/utils/Button/Button';

export const Container = styled.div`
  width: 100%;
  margin-top: 7rem;
  overflow: auto;
  padding-bottom: 3rem;

  @media only screen and (max-width: 768px) {
    margin-top: 0;
    padding: 2.4rem 2.2rem;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NoteText = styled(Text14Light)`
  line-height: 2rem;
  letter-spacing: -0.408px;
  opacity: 0.5;
  margin: 2rem 0;
`;

export const IconWrapper = styled.div`
  height: 2.4rem;
  width: 2.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyDiv = styled.div`
  width: 2.4rem;
`;

export const MethodsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin: 1rem 0 2rem 0;
`;

export const StyledButton = styled(Button)`
  width: 23rem;
  margin-inline: auto;
`;

export const Content = styled.div`
  max-width: 45rem;
  margin-inline: auto;
`;
