import React from 'react';
import { Container, Content, Form, StyledButton } from './ChangePasswordPage.styles';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectUser, selectUserActionsPending } from '../../../store/slices/user/slice';
import { changePassword } from '../../../store/slices/user/asyncThunks';
import { changePassword as changePasswordAgency } from '../../../store/slices/agency/asyncThunks';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { useOutletContext } from 'react-router-dom';
import { Header, Input } from '../../../components';
import { USER_ROLE } from '../../../utils/constants';
import { selectAgencyActionsPending } from '../../../store/slices/agency/slice';

const passwordFormSchema = yup
  .object({
    currentPassword: yup
      .string()
      .trim()
      .required('Current password is required')
      .min(6, 'Must be at least 6 characters'),
    password: yup.string().trim().required('New password is required').min(6, 'Must be at least 6 characters'),
  })
  .required();

const ChangePasswordPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { changePassword: changePasswordPending } = useSelector(selectUserActionsPending);
  const { changePassword: changePasswordAgencyPending } = useSelector(selectAgencyActionsPending);

  const [setShowContent] = useOutletContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      currentPassword: '',
      password: '',
    },
    delayError: 300,
    resolver: yupResolver(passwordFormSchema),
  });

  const onSubmit = data => {
    const { password, currentPassword } = data;

    if (user.role === USER_ROLE.AGENCY) {
      dispatch(changePasswordAgency({ password, currentPassword }))
        .unwrap()
        .then(() => {
          notifyInfo('Password changed successfully');
          reset();
        })
        .catch(err => {
          notifyError(err.message);
        });
    } else {
      dispatch(changePassword({ password, currentPassword }))
        .unwrap()
        .then(() => {
          notifyInfo('Password changed successfully');
          reset();
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Header title="Change Password" setShowContent={setShowContent}>
          <StyledButton title="Save" isLoading={changePasswordPending || changePasswordAgencyPending} />
        </Header>
        <Content>
          <Input
            label="Current Password"
            placeholder="Enter current password"
            {...register('currentPassword')}
            error={errors.currentPassword?.message}
            type="password"
          />
          <Input
            label="New Password"
            placeholder="Enter new password"
            {...register('password')}
            error={errors.password?.message}
            type="password"
          />
        </Content>
      </Form>
    </Container>
  );
};

export default ChangePasswordPage;
