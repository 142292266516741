import React, { useEffect } from 'react';
import {
  ChartContainer,
  ChartContent,
  ChartHeader,
  Content,
  Line,
  PageContainer,
  SpinnerContainer,
  TitleRightSide,
} from './AgencyHomePage.styles';
import { Text18Bold, Text24Bold } from '../../../components/utils/Text/Text.styles';
import {
  AgencyHomeBarChart,
  AgencyHomePieChart,
  AgencyInfluencerEarningsByMonth,
  DateRangePickerComponent,
  Select,
  Spinner,
} from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchMonthlyInfluencersEarnings,
  fetchTop10InfluencerEarnings,
} from '../../../store/slices/agency/asyncThunks';
import {
  selectAgencyActionsPending,
  selectTop10InfluencerEarnings,
  selectTop10InfluencersAndOthers,
} from '../../../store/slices/agency/slice';
import { selectDateRange, selectEarningType, selectUser, setEarningType } from '../../../store/slices/user/slice';
import { EARNINGS_TYPE } from '../../../utils/constants';

const AgencyHomePage = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const range = useSelector(selectDateRange);
  const earningType = useSelector(selectEarningType);
  const top10InfluencerEarnings = useSelector(selectTop10InfluencerEarnings);
  const top10InfluencersAndOthers = useSelector(selectTop10InfluencersAndOthers);
  const { fetchTop10InfluencerEarnings: fetchTop10InfluencerEarningsPending } = useSelector(selectAgencyActionsPending);

  useEffect(() => {
    dispatch(fetchTop10InfluencerEarnings({ startDate: range[0], endDate: range[1] }));
  }, [range, dispatch]);

  useEffect(() => {
    dispatch(fetchMonthlyInfluencersEarnings());
  }, [dispatch]);

  return (
    <PageContainer>
      <Text24Bold>{`Welcome, ${user.agencyName}!`}</Text24Bold>
      <Content>
        <ChartContainer>
          <ChartHeader>
            <Text18Bold>Overview</Text18Bold>
            <TitleRightSide>
              <DateRangePickerComponent />
              <Select
                options={[
                  { value: EARNINGS_TYPE.GROSS, label: 'Gross' },
                  { value: EARNINGS_TYPE.NET, label: 'Net' },
                ]}
                handleChange={value => dispatch(setEarningType(value))}
                selectedOption={earningType}
                hideError
              />
            </TitleRightSide>
          </ChartHeader>
          {!fetchTop10InfluencerEarningsPending ? (
            <ChartContent>
              {top10InfluencerEarnings && <AgencyHomeBarChart chartData={top10InfluencerEarnings} />}
              <Line />
              {top10InfluencersAndOthers && <AgencyHomePieChart chartData={top10InfluencersAndOthers} />}
            </ChartContent>
          ) : (
            <SpinnerContainer>
              <Spinner width={150} height={150} />
            </SpinnerContainer>
          )}
        </ChartContainer>
        <AgencyInfluencerEarningsByMonth />
      </Content>
    </PageContainer>
  );
};

export default AgencyHomePage;
