import React, { useEffect } from 'react';
import {
  AnalyticsContainer,
  ChartContainer,
  ChartContent,
  ChartHeader,
  Content,
  Line,
  SpinnerContainer,
  StatisticContainer,
  TitleRightSide,
  TotalContainer,
} from './InfluencerAnalitycsPage.styles';
import { Text14Regular, Text18Bold } from '../../../components/utils/Text/Text.styles';
import {
  AnalyticsCard,
  DateRangePickerComponent,
  HomeBarChart,
  InfluencerEarningsByMonth,
  Select,
  Spinner,
  StatisticCard,
} from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { DollarSign, EarningContainer, NetText } from '../../../components/home/AnalyticsCard/AnalyticsCard.styles';
import { formatPrice } from '../../../utils/util';
import { EARNINGS_TYPE, VALUE_OF_ONE_STAR_IN_USD } from '../../../utils/constants';
import { useParams } from 'react-router-dom';
import { fetchInfluencerEarningsByMonth } from '../../../store/slices/agency/asyncThunks';
import {
  selectAgencyActionsPending,
  selectInfluencerEarnings,
  selectMonthlyInfluencerEarnings,
} from '../../../store/slices/agency/slice';
import { ReactComponent as ImageIcon } from '../../../assets/icons/image.svg';
import { ReactComponent as VideoRecorderIcon } from '../../../assets/icons/video-recorder.svg';
import { ReactComponent as SaleIcon } from '../../../assets/icons/sale.svg';
import { ReactComponent as FilmIcon } from '../../../assets/icons/film.svg';
import { ReactComponent as WalletIcon } from '../../../assets/icons/wallet.svg';
import { ReactComponent as SafeIcon } from '../../../assets/icons/safe.svg';
import { ReactComponent as StarIcon } from '../../../assets/icons/star.svg';
import { ReactComponent as CreditCardIcon } from '../../../assets/icons/credit-card-down.svg';
import { selectEarningType, setEarningType } from '../../../store/slices/user/slice';

const InfluencerAnalitycsPage = () => {
  const dispatch = useDispatch();
  const { id: influencerId } = useParams();
  const influencerEarnings = useSelector(selectInfluencerEarnings);
  const {
    fetchInfluencerEarnings: fetchInfluencerEarningsPending,
    fetchInfluencerEarningsByMonth: fetchInfluencerEarningsByMonthPending,
  } = useSelector(selectAgencyActionsPending);

  const earningType = useSelector(selectEarningType);
  const monthlyInfluencerEarnings = useSelector(selectMonthlyInfluencerEarnings);

  useEffect(() => {
    if (influencerId) {
      dispatch(fetchInfluencerEarningsByMonth({ influencerId }));
    }
  }, [influencerId, dispatch]);

  const totalEarnings = influencerEarnings?.overview
    ? influencerEarnings.overview.photo.earnings +
      influencerEarnings.overview.video.earnings +
      influencerEarnings.overview.bundle.earnings +
      influencerEarnings.overview.subscription.earnings +
      influencerEarnings.overview.other.earnings
    : 0;

  if (!influencerEarnings) {
    return (
      <SpinnerContainer>
        <Spinner width={100} height={100} />
      </SpinnerContainer>
    );
  }

  return (
    <>
      <Content>
        <StatisticContainer>
          <StatisticCard
            title="Available Balance"
            icon={<CreditCardIcon />}
            value={influencerEarnings.earnings.available * VALUE_OF_ONE_STAR_IN_USD}
            valuePrefix="$"
            hideGross
          />
          <StatisticCard
            title="Pending Balance"
            icon={<WalletIcon color="#BC9EFF" />}
            value={
              (influencerEarnings.earnings.balance - influencerEarnings.earnings.available) * VALUE_OF_ONE_STAR_IN_USD
            }
            valuePrefix="$"
            hideGross
          />
          <StatisticCard
            title="Lifetime Proceeds"
            icon={<SafeIcon />}
            value={influencerEarnings.earnings.lifetime * VALUE_OF_ONE_STAR_IN_USD}
            valuePrefix="$"
          />
        </StatisticContainer>
        <ChartContainer>
          <ChartHeader>
            <Text18Bold>Overview</Text18Bold>
            <TitleRightSide>
              <DateRangePickerComponent />
              <Select
                options={[
                  { value: EARNINGS_TYPE.GROSS, label: 'Gross' },
                  { value: EARNINGS_TYPE.NET, label: 'Net' },
                ]}
                handleChange={value => dispatch(setEarningType(value))}
                selectedOption={earningType}
                hideError
              />
            </TitleRightSide>
          </ChartHeader>
          {!fetchInfluencerEarningsPending ? (
            <ChartContent>
              <HomeBarChart dailyAnalytics={influencerEarnings.daily} />
              <Line />
              <AnalyticsContainer>
                <AnalyticsCard
                  icon={<ImageIcon />}
                  title="Photo Sales"
                  earnings={influencerEarnings.overview.photo.earnings}
                />
                <AnalyticsCard
                  icon={<VideoRecorderIcon />}
                  title="Video Sales"
                  earnings={influencerEarnings.overview.video.earnings}
                />
                <AnalyticsCard
                  icon={<FilmIcon />}
                  title="Bundle Sales"
                  earnings={influencerEarnings.overview.bundle.earnings}
                />
                <AnalyticsCard
                  icon={<StarIcon />}
                  title="Subscription Sales"
                  earnings={influencerEarnings.overview.subscription.earnings}
                />
                <AnalyticsCard
                  icon={<SaleIcon />}
                  title="Other Sales"
                  earnings={influencerEarnings.overview.other.earnings}
                />
                <TotalContainer>
                  <EarningContainer>
                    <NetText>Total Net:</NetText>
                    <DollarSign>$</DollarSign>
                    <Text14Regular>{formatPrice(totalEarnings * VALUE_OF_ONE_STAR_IN_USD)}</Text14Regular>
                  </EarningContainer>
                  <EarningContainer>
                    <NetText>Total Gross:</NetText>
                    <DollarSign>$</DollarSign>
                    <Text18Bold>{formatPrice(totalEarnings * VALUE_OF_ONE_STAR_IN_USD * 2)}</Text18Bold>
                  </EarningContainer>
                </TotalContainer>
              </AnalyticsContainer>
            </ChartContent>
          ) : (
            <SpinnerContainer>
              <Spinner width={100} height={100} />
            </SpinnerContainer>
          )}
        </ChartContainer>
        <InfluencerEarningsByMonth
          monthlyInfluencerEarnings={monthlyInfluencerEarnings}
          pending={fetchInfluencerEarningsByMonthPending}
        />
      </Content>
    </>
  );
};

export default InfluencerAnalitycsPage;
