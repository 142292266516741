import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/auth/slice';
import userSlice from './slices/user/slice';
import influencerRequestSlice from './slices/influencerRequest/slice';
import paymentSlice from './slices/payment/slice';
import withdrawalSlice from './slices/withdrawal/slice';
import agencySlice from './slices/agency/slice';
import influencerFactSlice from './slices/influencerFact/slice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    userInfo: userSlice,
    influencerRequestInfo: influencerRequestSlice,
    paymentInfo: paymentSlice,
    withdrawalInfo: withdrawalSlice,
    agencyInfo: agencySlice,
    influencerFactInfo: influencerFactSlice,
  },
  devTools: false,
});
