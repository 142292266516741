import React from 'react';
import {
  Badge,
  LogoWrapper,
  NavLinkIconWrapper,
  NavLinkText,
  NavigationContainer,
  NavigationItem,
  NavigationItems,
  NavigationWrapper,
} from './Navigation.styles';
import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as WalletIcon } from '../../assets/icons/wallet.svg';
import { ReactComponent as UsersIcon } from '../../assets/icons/users.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { NavLink } from 'react-router-dom';
import { AGENCY_HOME, AGENCY_INFLUENCERS, AGENCY_SETTINGS, HOME, PROFILE, WALLET } from '../App/routes';
import LogoComponent from '../LogoComponent/LogoComponent';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/user/slice';
import { USER_ROLE } from '../../utils/constants';

const Navigation = () => {
  const user = useSelector(selectUser);

  const renderInfluencerNavigation = () => {
    return (
      <NavigationContainer>
        <NavigationItems>
          <LogoWrapper>
            <LogoComponent />
          </LogoWrapper>
          <NavigationItem>
            <NavLink to={HOME}>
              <Badge />
              <NavLinkIconWrapper>
                <HomeIcon />
              </NavLinkIconWrapper>
              <NavLinkText>Home</NavLinkText>
            </NavLink>
          </NavigationItem>
          <NavigationItem>
            <NavLink to={WALLET}>
              <Badge />
              <NavLinkIconWrapper>
                <WalletIcon />
              </NavLinkIconWrapper>
              <NavLinkText>Wallet</NavLinkText>
            </NavLink>
          </NavigationItem>
          <NavigationItem>
            <NavLink to={PROFILE}>
              <Badge />
              <NavLinkIconWrapper>
                <UserIcon />
              </NavLinkIconWrapper>
              <NavLinkText>Profile</NavLinkText>
            </NavLink>
          </NavigationItem>
        </NavigationItems>
      </NavigationContainer>
    );
  };

  const renderAgencyNavigation = () => {
    return (
      <NavigationContainer>
        <NavigationItems>
          <LogoWrapper>
            <LogoComponent />
          </LogoWrapper>
          <NavigationItem>
            <NavLink to={AGENCY_HOME}>
              <Badge />
              <NavLinkIconWrapper>
                <HomeIcon />
              </NavLinkIconWrapper>
              <NavLinkText>Home</NavLinkText>
            </NavLink>
          </NavigationItem>
          <NavigationItem>
            <NavLink to={AGENCY_INFLUENCERS}>
              <Badge />
              <NavLinkIconWrapper>
                <UsersIcon />
              </NavLinkIconWrapper>
              <NavLinkText>Creators</NavLinkText>
            </NavLink>
          </NavigationItem>
          <NavigationItem>
            <NavLink to={AGENCY_SETTINGS}>
              <Badge />
              <NavLinkIconWrapper>
                <SettingsIcon />
              </NavLinkIconWrapper>
              <NavLinkText>Settings</NavLinkText>
            </NavLink>
          </NavigationItem>
        </NavigationItems>
      </NavigationContainer>
    );
  };

  return (
    <NavigationWrapper>
      {user.role === USER_ROLE.INFLUENCER ? renderInfluencerNavigation() : renderAgencyNavigation()}
    </NavigationWrapper>
  );
};

export default Navigation;
