import React, { useEffect } from 'react';
import { Container, ContentContainer, EmptyDiv, Header, IconWrapper } from './WithdrawalMethodPage.styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { WITHDRAW } from '../../components/App/routes';
import { WITHDRAWAL_METHOD_TYPE } from '../../utils/constants';
import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/chevron_left.svg';
import { Text20Bold } from '../../components/utils/Text/Text.styles';
import { CryptoForm, DomesticWireForm, InternationalWireForm } from '../../components';

const TITLE = {
  [WITHDRAWAL_METHOD_TYPE.CRYPTO]: 'Crypto',
  [WITHDRAWAL_METHOD_TYPE.DOMESTIC_WIRE]: 'Domestic Wire',
  [WITHDRAWAL_METHOD_TYPE.INTERNATIONAL_WIRE]: 'International Wire',
};

const WithdrawalMethodPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const type = state?.type;

  useEffect(() => {
    if (!type) {
      navigate(`/${WITHDRAW}`);
    }
  }, [type, navigate]);

  const renderContent = () => {
    switch (type) {
      case WITHDRAWAL_METHOD_TYPE.CRYPTO:
        return <CryptoForm />;
      case WITHDRAWAL_METHOD_TYPE.DOMESTIC_WIRE:
        return <DomesticWireForm />;
      case WITHDRAWAL_METHOD_TYPE.INTERNATIONAL_WIRE:
        return <InternationalWireForm />;
      default:
        return;
    }
  };

  return (
    <Container>
      <ContentContainer>
        <Header>
          <IconWrapper onClick={() => navigate(`/${WITHDRAW}`)}>
            <ChevronLeftIcon />
          </IconWrapper>
          <Text20Bold>{TITLE[type]}</Text20Bold>
          <EmptyDiv />
        </Header>
        {renderContent()}
      </ContentContainer>
    </Container>
  );
};

export default WithdrawalMethodPage;
