import styled from 'styled-components';

export const ModalStyle = styled.div`
  position: relative;
  outline: none;
  border-radius: 24px;
  border: 1px solid #ddd;
  background: #fff;
  padding: 2rem 2.8rem;
  height: 42rem;

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 600px) {
    max-height: unset;
    width: 90%;
    padding: 1.2rem 2rem;
  }
`;

export const OverlayStyle = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3500;
  background: rgba(0, 0, 0, 0.42);
  backdrop-filter: blur(5px);
  opacity: 0;
  transition: all 300ms ease-in-out;

  &[class*='--after-open'] {
    opacity: 1;
  }

  &[class*='--before-close'] {
    opacity: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CloseIconWrapper = styled.div`
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -0.8rem;
`;

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
