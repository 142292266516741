import React, { useState } from 'react';
import { ReactComponent as ChevronLeft } from '../../../assets/icons/chevron_left.svg';
import {
  AccordionContainer,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  Icon,
  RightSideContent,
} from './Accordion.styles';
import { Text15Light } from '../Text/Text.styles';

const Accordion = ({ items, contentHeight = 430 }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <AccordionContainer>
      {items.map((item, index) => (
        <AccordionItem key={index} $isOpen={openIndex === index}>
          <AccordionHeader onClick={() => handleToggle(index)}>
            <Text15Light>{item.title}</Text15Light>
            <RightSideContent>{openIndex !== index && item.value}</RightSideContent>
            <Icon $isOpen={openIndex === index}>
              <ChevronLeft />
            </Icon>
          </AccordionHeader>
          <AccordionContent $contentHeight={contentHeight} $isOpen={openIndex === index}>
            {item.content}
          </AccordionContent>
        </AccordionItem>
      ))}
    </AccordionContainer>
  );
};

export default Accordion;
