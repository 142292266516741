import {
  changePassword,
  fetchInfluencerAnalytics,
  fetchInfluencerEarnings,
  fetchInfluencerEarningsByMonth,
  fetchMonthlyInfluencersEarnings,
  fetchTop10InfluencerEarnings,
  updateAgencyBasicInfo,
} from './asyncThunks';

export const changePasswordBuilder = builder => {
  builder.addCase(changePassword.pending, state => {
    state.pending.changePassword = true;
  });
  builder.addCase(changePassword.fulfilled, state => {
    state.pending.changePassword = false;
  });
  builder.addCase(changePassword.rejected, state => {
    state.pending.changePassword = false;
  });
};

export const updateAgencyBasicInfoBuilder = builder => {
  builder.addCase(updateAgencyBasicInfo.pending, state => {
    state.pending.updateAgencyBasicInfo = true;
  });
  builder.addCase(updateAgencyBasicInfo.fulfilled, state => {
    state.pending.updateAgencyBasicInfo = false;
  });
  builder.addCase(updateAgencyBasicInfo.rejected, state => {
    state.pending.updateAgencyBasicInfo = false;
  });
};

export const fetchInfluencerEarningsBuilder = builder => {
  builder.addCase(fetchInfluencerEarnings.pending, state => {
    state.pending.fetchInfluencerEarnings = true;
  });
  builder.addCase(fetchInfluencerEarnings.fulfilled, (state, { payload }) => {
    state.pending.fetchInfluencerEarnings = false;
    state.influencerEarnings = payload;
  });
  builder.addCase(fetchInfluencerEarnings.rejected, state => {
    state.pending.fetchInfluencerEarnings = false;
  });
};

export const fetchTop10InfluencerEarningsBuilder = builder => {
  builder.addCase(fetchTop10InfluencerEarnings.pending, state => {
    state.pending.fetchTop10InfluencerEarnings = true;
  });
  builder.addCase(fetchTop10InfluencerEarnings.fulfilled, (state, { payload }) => {
    state.pending.fetchTop10InfluencerEarnings = false;
    state.top10InfluencerEarnings = payload.top10Influencers;
    state.top10InfluencersAndOthers = payload.top10InfluencersAndOthers;
  });
  builder.addCase(fetchTop10InfluencerEarnings.rejected, state => {
    state.pending.fetchTop10InfluencerEarnings = false;
  });
};

export const fetchMonthlyInfluencersEarningsBuilder = builder => {
  builder.addCase(fetchMonthlyInfluencersEarnings.pending, state => {
    state.pending.fetchMonthlyInfluencersEarnings = true;
  });
  builder.addCase(fetchMonthlyInfluencersEarnings.fulfilled, (state, { payload }) => {
    state.pending.fetchMonthlyInfluencersEarnings = false;
    state.influencerEarningsByMonth = payload.influencerEarningsByMonth;
  });
  builder.addCase(fetchMonthlyInfluencersEarnings.rejected, state => {
    state.pending.fetchMonthlyInfluencersEarnings = false;
  });
};

export const fetchInfluencerEarningsByMonthBuilder = builder => {
  builder.addCase(fetchInfluencerEarningsByMonth.pending, state => {
    state.pending.fetchInfluencerEarningsByMonth = true;
  });
  builder.addCase(fetchInfluencerEarningsByMonth.fulfilled, (state, { payload }) => {
    state.pending.fetchInfluencerEarningsByMonth = false;
    state.monthlyInfluencerEarnings = payload.monthlyInfluencerEarnings;
  });
  builder.addCase(fetchInfluencerEarningsByMonth.rejected, state => {
    state.pending.fetchInfluencerEarningsByMonth = false;
  });
};

export const fetchInfluencerAnalyticsBuilder = builder => {
  builder.addCase(fetchInfluencerAnalytics.pending, state => {
    state.fetchInfluencerAnalyticsInfo.pending = true;
  });
  builder.addCase(fetchInfluencerAnalytics.fulfilled, (state, { payload }) => {
    const { data, total } = payload;
    state.fetchInfluencerAnalyticsInfo.pending = false;
    state.fetchInfluencerAnalyticsInfo.data = data;
    state.fetchInfluencerAnalyticsInfo.total = total;
  });
  builder.addCase(fetchInfluencerAnalytics.rejected, state => {
    state.fetchInfluencerAnalyticsInfo.pending = false;
  });
};
