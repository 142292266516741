import styled from 'styled-components';
import { Text14Regular } from '../../utils/Text/Text.styles';

export const Container = styled.div`
  border-radius: 10px;
  background: #f5f5fd;
  height: 3.8rem;
  display: flex;
  padding: 0.4rem;
  margin-bottom: 2rem;
`;

export const Button = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ $active }) => ($active ? ' #eeecfb' : 'transparent')};
  background: ${({ $active }) => ($active ? ' #fff' : 'transparent')};
  box-shadow: 4px 5px 2px 0px rgba(0, 0, 0, 0), 2px 3px 2px 0px rgba(0, 0, 0, 0.01);
  flex: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonLabel = styled(Text14Regular)`
  opacity: ${({ $active }) => !$active && 0.5};
`;
