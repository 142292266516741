import React from 'react';
import { Card, CardContent, CategoryText, DollarSign, PriceContainer } from './AnalyticsCard.styles';
import { Text22Bold } from '../../utils/Text/Text.styles';
import { EARNINGS_TYPE, VALUE_OF_ONE_STAR_IN_USD } from '../../../utils/constants';
import { formatPrice } from '../../../utils/util';
import { useSelector } from 'react-redux';
import { selectEarningType } from '../../../store/slices/user/slice';

const AnalyticsCard = ({ earnings, icon, title }) => {
  const earningType = useSelector(selectEarningType);
  const multiplier = earningType.value === EARNINGS_TYPE.NET ? 1 : 2;

  return (
    <Card>
      {icon}
      <CardContent>
        <CategoryText>{title}</CategoryText>
        <PriceContainer>
          <DollarSign>$</DollarSign>
          <Text22Bold>{formatPrice(earnings * VALUE_OF_ONE_STAR_IN_USD * multiplier)}</Text22Bold>
        </PriceContainer>
      </CardContent>
    </Card>
  );
};

export default AnalyticsCard;
