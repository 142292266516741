import React, { useEffect } from 'react';
import {
  BackContainer,
  IconWrapper,
  NavigationContainer,
  NavigatorItem,
  NavLinkText,
  PageContainer,
  SpinnerContainer,
} from './InfluencerPage.styles';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/chevron_left.svg';
import { Text24Bold } from '../../../components/utils/Text/Text.styles';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectInfluencerEarnings, setInfluencerEarnings } from '../../../store/slices/agency/slice';
import { Spinner } from '../../../components';
import { fetchInfluencerEarnings } from '../../../store/slices/agency/asyncThunks';
import { selectDateRange } from '../../../store/slices/user/slice';
import { AGENCY_INFLUENCERS, INFLUENCER_ANALYTICS, INFLUENCER_FACTS } from '../../../components/App/routes';

const InfluencerPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: influencerId } = useParams();
  const influencerEarnings = useSelector(selectInfluencerEarnings);
  const range = useSelector(selectDateRange);

  const onBack = () => {
    navigate(`/${AGENCY_INFLUENCERS}`);
  };

  useEffect(() => {
    if (influencerId) {
      dispatch(fetchInfluencerEarnings({ influencerId, startDate: range[0], endDate: range[1] }));
    }
  }, [range, influencerId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setInfluencerEarnings(null));
    };
  }, [dispatch]);

  if (!influencerEarnings) {
    return (
      <SpinnerContainer>
        <Spinner width={100} height={100} />
      </SpinnerContainer>
    );
  }

  return (
    <PageContainer>
      <BackContainer>
        <IconWrapper onClick={onBack}>
          <ArrowIcon />
        </IconWrapper>
        <Text24Bold>{influencerEarnings.influencerName}</Text24Bold>
      </BackContainer>
      <NavigationContainer>
        <NavigatorItem>
          <NavLink to={INFLUENCER_ANALYTICS}>
            <NavLinkText>Overview</NavLinkText>
          </NavLink>
        </NavigatorItem>
        <NavigatorItem>
          <NavLink to={INFLUENCER_FACTS}>
            <NavLinkText>Facts</NavLinkText>
          </NavLink>
        </NavigatorItem>
      </NavigationContainer>
      <Outlet />
    </PageContainer>
  );
};

export default InfluencerPage;
