import React, { useCallback, useMemo } from 'react';
import Accordion from '../utils/Accordion/Accordion';
import {
  AccordionItemContent,
  AccordionItemFooter,
  Avatar,
  DollarSign,
  EarningsByMonthContainer,
  FooterText,
  FooterTextContainer,
  ItemCard,
  ItemContentContainer,
  LeftContent,
  RightContent,
} from './AgencyInfluencerEarningsByMonth.styles';
import { useSelector } from 'react-redux';
import { selectAgencyActionsPending, selectInfluencerEarningsByMonth } from '../../store/slices/agency/slice';
import { format, parse } from 'date-fns';
import { Text14Regular, Text15Light, Text18Bold } from '../utils/Text/Text.styles';
import Spinner from '../Spinner/Spinner';
import { formatPrice } from '../../utils/util';
import { VALUE_OF_ONE_STAR_IN_USD } from '../../utils/constants';
import { AGENCY_INFLUENCERS } from '../App/routes';
import { useNavigate } from 'react-router-dom';

const AgencyInfluencerEarningsByMonth = () => {
  const navigate = useNavigate();
  const influencerEarningsByMonth = useSelector(selectInfluencerEarningsByMonth);
  const { fetchMonthlyInfluencersEarnings: fetchMonthlyInfluencersEarningsPending } =
    useSelector(selectAgencyActionsPending);

  const renderAccordionItemContent = useCallback(
    (data, totalEarnings) => {
      return (
        <AccordionItemContent>
          <ItemContentContainer>
            {data.map((item, index) => {
              return (
                <ItemCard key={index} onClick={() => navigate(`/${AGENCY_INFLUENCERS}/${item.influencer.id}`)}>
                  <LeftContent>
                    <Avatar src={item.influencer.profilePhoto} />
                    <Text15Light>{item.influencer.name}</Text15Light>
                  </LeftContent>
                  <RightContent>
                    <FooterTextContainer>
                      <FooterText>Net: </FooterText>
                      <DollarSign>$</DollarSign>
                      <Text14Regular>{formatPrice(item.earnings * VALUE_OF_ONE_STAR_IN_USD)}</Text14Regular>
                    </FooterTextContainer>
                    <FooterTextContainer>
                      <FooterText>Gross: </FooterText>
                      <DollarSign>$</DollarSign>
                      <Text18Bold>{formatPrice(item.earnings * VALUE_OF_ONE_STAR_IN_USD * 2)}</Text18Bold>
                    </FooterTextContainer>
                  </RightContent>
                </ItemCard>
              );
            })}
          </ItemContentContainer>
          <AccordionItemFooter>
            <FooterTextContainer>
              <FooterText>Net: </FooterText>
              <DollarSign>$</DollarSign>
              <Text14Regular>{formatPrice(totalEarnings * VALUE_OF_ONE_STAR_IN_USD)}</Text14Regular>
            </FooterTextContainer>
            <FooterTextContainer>
              <FooterText>Gross: </FooterText>
              <DollarSign>$</DollarSign>
              <Text18Bold>{formatPrice(totalEarnings * VALUE_OF_ONE_STAR_IN_USD * 2)}</Text18Bold>
            </FooterTextContainer>
          </AccordionItemFooter>
        </AccordionItemContent>
      );
    },
    [navigate],
  );

  const data = useMemo(() => {
    if (influencerEarningsByMonth) {
      return Object.entries(influencerEarningsByMonth).map(([key, value]) => {
        const parsedDate = parse(key, 'yyyy-M', new Date());
        const monthName = format(parsedDate, 'MMMM');
        const totalEarnings = value.reduce((acc, curr) => acc + curr.earnings, 0);

        return {
          title: monthName,
          value: (
            <AccordionItemFooter>
              <FooterTextContainer>
                <FooterText>Net: </FooterText>
                <DollarSign>$</DollarSign>
                <Text14Regular>{formatPrice(totalEarnings * VALUE_OF_ONE_STAR_IN_USD)}</Text14Regular>
              </FooterTextContainer>
              <FooterTextContainer>
                <FooterText>Gross: </FooterText>
                <DollarSign>$</DollarSign>
                <Text18Bold>{formatPrice(totalEarnings * VALUE_OF_ONE_STAR_IN_USD * 2)}</Text18Bold>
              </FooterTextContainer>
            </AccordionItemFooter>
          ),
          content: renderAccordionItemContent(value, totalEarnings),
        };
      });
    }

    return [];
  }, [influencerEarningsByMonth, renderAccordionItemContent]);

  return (
    <EarningsByMonthContainer>
      {fetchMonthlyInfluencersEarningsPending ? <Spinner width={150} height={150} /> : <Accordion items={data} />}
    </EarningsByMonthContainer>
  );
};

export default AgencyInfluencerEarningsByMonth;
