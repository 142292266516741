import styled from 'styled-components';
import { Text14Light, Text16Bold } from '../../utils/Text/Text.styles';

export const Card = styled.div`
  border-radius: 17px;
  border: 1px solid #f2f2f2;
  display: flex;
  gap: 1.2rem;
  padding: 1.2rem;
  width: 37rem;
  min-height: 7rem;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1100px) {
    width: 25rem;
  }

  @media only screen and (max-width: 980px) {
    width: 20rem;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.4rem;
  width: 100%;
`;

export const CategoryText = styled(Text16Bold)`
  margin-top: auto;
  margin-bottom: auto;
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const EarningContainer = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: center;
  margin-left: 1rem;
`;

export const DollarSign = styled(Text14Light)`
  opacity: 0.31;
  margin-bottom: 0.6rem;
`;

export const NetText = styled(Text14Light)`
  opacity: 0.7;
`;

export const EarningsContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
