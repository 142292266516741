import styled from 'styled-components';
import { Button } from '../../../components/utils/Button/Button';
import Input from '../../../components/utils/inputs/Input/Input';

export const Container = styled.div`
  height: 100%;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 5.6rem);
`;

export const InfiniteScrollMarker = styled.div`
  height: 1rem;
`;

export const LoaderMoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListContainer = styled.div`
  overflow: auto;
  height: calc(100% - 5.6rem);
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
`;

export const RowItem = styled.div`
  padding: 1.2rem;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid var(--Stroke, #f2f2f2);
  background: #fff;
  box-shadow: 4px 5px 2px 0px rgba(0, 0, 0, 0), 2px 3px 2px 0px rgba(0, 0, 0, 0.01);
  gap: 2.4rem;
`;

export const ActionContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;

export const ActionButton = styled.button`
  border-radius: 12px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: inherit;
  height: 3.2rem;
  width: 3.2rem;
  background: #f7f7f7;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6rem;
`;

export const StyledButton = styled(Button)`
  width: unset;
  height: 4rem;
  font-size: 1.4rem;
  padding-inline: 1.6rem;
`;

export const StyledInput = styled(Input)`
  width: unset;

  height: 4rem;

  input {
    height: 4rem;
    font-size: 1.4rem;
  }
`;
