import React, { useEffect } from 'react';
import { Container, Content, Form, StyledButton } from './AgencyProfilePage.styles';
import { Header, Input } from '../../../components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../store/slices/user/slice';
import { selectAgencyActionsPending } from '../../../store/slices/agency/slice';
import { useOutletContext } from 'react-router-dom';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { updateAgencyBasicInfo } from '../../../store/slices/agency/asyncThunks';

export const updateBasicInfoFormSchema = yup
  .object({
    name: yup.string().trim().required('Name is required').max(255, 'Max length reached'),
  })
  .required();

const AgencyProfilePage = () => {
  const user = useSelector(selectUser);
  const { updateAgencyBasicInfo: updateAgencyBasicInfoPending } = useSelector(selectAgencyActionsPending);
  const dispatch = useDispatch();
  const [setShowContent] = useOutletContext();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      dateOfBirth: null,
      profilePhoto: null,
    },
    delayError: 300,
    resolver: yupResolver(updateBasicInfoFormSchema),
  });

  useEffect(() => {
    if (user) {
      setValue('name', user.name);
    }
  }, [user, setValue]);

  const onSubmit = data => {
    dispatch(updateAgencyBasicInfo(data))
      .unwrap()
      .then(() => {
        notifyInfo('Profile info updated');
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Header title="Personal Info" setShowContent={setShowContent}>
          <StyledButton title="Save" isLoading={updateAgencyBasicInfoPending} />
        </Header>
        <Content>
          <Input label="Email" value={user.email} disabled />
          <Input label="Profile Name" placeholder="Enter name" {...register('name')} error={errors.name?.message} />
        </Content>
      </Form>
    </Container>
  );
};

export default AgencyProfilePage;
