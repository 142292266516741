import { createSlice } from '@reduxjs/toolkit';
import {
  changePasswordBuilder,
  createInfluencerRequestPasswordBuilder,
  fetchInfluencersBuilder,
  fetchUserEarningsBuilder,
  fetchUserInfoBuilder,
  fetchUserMonthlyEarningsBuilder,
  handleWithdrawalMethodsBuilder,
  loginBuilder,
  redeemInvitationCodeBuilder,
  requestWithdrawalBuilder,
  updateAgencyBasicInfoBuilder,
  updateBasicInfoBuilder,
  updateUserBasicInfoBuilder,
  uploadAudioBuilder,
  uploadPhotoBuilder,
  uploadVideoBuilder,
  verifyEmailBuilder,
} from './builder';
import { EARNINGS_TYPE, LOGOUT, UPLOAD_FILE_CONSTRAINTS } from '../../../utils/constants';
import { endOfDay, startOfMonth } from 'date-fns';

const INIT_STATE = {
  user: null,
  fetchInfluencersInfo: {
    pending: false,
    data: [],
    total: 0,
  },
  monthlyInfluencerEarnings: null,
  pending: {
    fetchUserInfo: false,
    changePassword: false,
    fetchUserEarnings: false,
    handleWithdrawalMethods: false,
    updateUserBasicInfo: false,
    fetchUserMonthlyEarnings: false,
  },
  dateRange: [startOfMonth(new Date()).toISOString(), endOfDay(new Date()).toISOString()],
  earningType: { value: EARNINGS_TYPE.GROSS, label: 'Gross' },
};

export const userSlice = createSlice({
  name: 'user',
  initialState: INIT_STATE,
  reducers: {
    updateNumOfUpdatedMedia: (state, { payload }) => {
      const { numOfMedia, type } = payload;
      if (type === UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO) {
        state.user.numOfUploadedPhotos += numOfMedia;
      } else if (type === UPLOAD_FILE_CONSTRAINTS.TYPE.VIDEO) {
        state.user.numOfUploadedVideos += numOfMedia;
      } else {
        state.user.numOfUploadedAudios += numOfMedia;
      }
    },
    setUserStatus: (state, { payload }) => {
      state.user.status = payload;
    },
    setDateRange: (state, { payload }) => {
      state.dateRange = payload;
    },
    setEarningType: (state, { payload }) => {
      state.earningType = payload;
    },
  },
  extraReducers: builder => {
    fetchUserInfoBuilder(builder);
    loginBuilder(builder);
    changePasswordBuilder(builder);
    verifyEmailBuilder(builder);
    createInfluencerRequestPasswordBuilder(builder);
    redeemInvitationCodeBuilder(builder);
    updateBasicInfoBuilder(builder);
    uploadPhotoBuilder(builder);
    uploadVideoBuilder(builder);
    uploadAudioBuilder(builder);
    fetchUserEarningsBuilder(builder);
    handleWithdrawalMethodsBuilder(builder);
    updateUserBasicInfoBuilder(builder);
    requestWithdrawalBuilder(builder);
    fetchInfluencersBuilder(builder);
    updateAgencyBasicInfoBuilder(builder);
    fetchUserMonthlyEarningsBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const { updateNumOfUpdatedMedia, setUserStatus, setDateRange, setEarningType } = userSlice.actions;

export const selectUser = state => state.userInfo.user;
export const selectFetchInfluencersInfo = state => state.userInfo.fetchInfluencersInfo;
export const selectMonthlyInfluencerEarnings = state => state.userInfo.monthlyInfluencerEarnings;
export const selectUserActionsPending = state => state.userInfo.pending;
export const selectDateRange = state => state.userInfo.dateRange;
export const selectEarningType = state => state.userInfo.earningType;

export default userSlice.reducer;
