import React, { useEffect, useRef } from 'react';
import { Container, Content, Form, StyledButton } from './PersonalInfoPage.styles';
import { DatePicker, Header, Input, UploadProfilePhotoInput } from '../../../components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { UPLOAD_FILE_CONSTRAINTS } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, selectUserActionsPending } from '../../../store/slices/user/slice';
import CropImageModal from '../../../components/modals/CropImageModal/CropImageModal';
import { updateUserBasicInfo } from '../../../store/slices/user/asyncThunks';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { useOutletContext } from 'react-router-dom';

export const updateBasicInfoFormSchema = yup
  .object({
    name: yup.string().trim().required('Name is required').max(255, 'Max length reached'),
    dateOfBirth: yup
      .date()
      .required('Birth of date is required')
      .max(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), 'Influencer must be 18+'),
    profilePhoto: yup.mixed().required('Profile photo is required'),
  })
  .required();

const PersonalInfoPage = () => {
  const cropImageRef = useRef();
  const user = useSelector(selectUser);
  const { updateUserBasicInfo: updateUserBasicInfoPending } = useSelector(selectUserActionsPending);
  const dispatch = useDispatch();
  const [setShowContent] = useOutletContext();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      dateOfBirth: null,
      profilePhoto: null,
    },
    delayError: 300,
    resolver: yupResolver(updateBasicInfoFormSchema),
  });

  const watchProfilePhoto = watch('profilePhoto');

  useEffect(() => {
    if (watchProfilePhoto) {
      if (typeof watchProfilePhoto !== 'string') {
        cropImageRef.current.show();
      }
    }
  }, [watchProfilePhoto]);

  useEffect(() => {
    if (user) {
      setValue('name', user.name);
      setValue('dateOfBirth', user.dateOfBirth);
      setValue('profilePhoto', user.profilePhoto);
    }
  }, [user, setValue]);

  const onSubmit = data => {
    let profilePhoto = null;
    if (!data.profilePhoto.startsWith('https://')) {
      profilePhoto = data.profilePhoto;
    }
    dispatch(
      updateUserBasicInfo({
        name: data.name,
        dateOfBirth: data.dateOfBirth,
        profilePhoto,
      }),
    )
      .unwrap()
      .then(() => {
        notifyInfo('Profile info updated');
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Header title="Personal Info" setShowContent={setShowContent}>
          <StyledButton title="Save" isLoading={updateUserBasicInfoPending} />
        </Header>
        <Content>
          <Controller
            name="profilePhoto"
            control={control}
            render={({ field }) => {
              return (
                <UploadProfilePhotoInput
                  label="Profile photo"
                  value={field.value}
                  onChange={field.onChange}
                  type={UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO}
                  error={errors.profilePhoto?.message}
                />
              );
            }}
          />
          <Input label="Profile Name" placeholder="Enter name" {...register('name')} error={errors.name?.message} />
          <Controller
            name="dateOfBirth"
            control={control}
            render={({ field }) => {
              return (
                <DatePicker
                  label="Date of birth"
                  placeholder="Enter date of birth"
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.dateOfBirth?.message}
                />
              );
            }}
          />
        </Content>
      </Form>
      <CropImageModal
        ref={cropImageRef}
        onClose={() => {
          setValue('profilePhoto', null);
          cropImageRef.current.hide();
        }}
        onComplete={croppedImage => {
          setValue('profilePhoto', croppedImage);
          cropImageRef.current.hide();
        }}
        originalImage={getValues('profilePhoto')}
      />
    </Container>
  );
};

export default PersonalInfoPage;
