import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ActionButton,
  ActionContainer,
  Container,
  InfiniteScrollMarker,
  ListContainer,
  LoaderContainer,
  LoaderMoreContainer,
  RowItem,
  StyledButton,
} from './FactsPage.styles';
import { Header, NewInfluencerFactFormModal, Spinner } from '../../../components';
import { useOutletContext } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFetchInfluencerFactsInfo,
  selectInfluencerFactActionsPending,
} from '../../../store/slices/influencerFact/slice';
import { deleteInfluencerFact, fetchInfluencerFacts } from '../../../store/slices/influencerFact/asyncThunks';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/pencil.svg';
import { Text14Bold } from '../../../components/utils/Text/Text.styles';

const FactsPage = () => {
  const dispatch = useDispatch();
  const loadMoreRef = useRef(null);
  const addNewFactModalRef = useRef(null);
  const [setShowContent] = useOutletContext();
  const [page, setPage] = useState(1);
  // const [search, setSearch] = useState('');
  const { data, maxReached, pending, fetchMorePending } = useSelector(selectFetchInfluencerFactsInfo);
  const { deleteInfluencerFact: deleteInfluencerFactPending } = useSelector(selectInfluencerFactActionsPending);
  const [selectedFact, setSelectedFact] = useState(null);

  // const changeHandler = useCallback(text => {
  //   setSearch(text);
  // }, []);

  // const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  const disabledActions = deleteInfluencerFactPending;

  useEffect(() => {
    setPage(1);
    dispatch(fetchInfluencerFacts({ page: 1, search: '' }));
  }, [dispatch]);

  const handleIntersection = useCallback(
    entries => {
      const [entry] = entries;
      if (entry.isIntersecting && !pending && !fetchMorePending && !maxReached) {
        setPage(prev => prev + 1);
        dispatch(fetchInfluencerFacts({ page: page + 1, search: '' }));
      }
    },
    [pending, maxReached, fetchMorePending, page, dispatch],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, { root: null, threshold: 1.0 });
    const target = loadMoreRef.current;
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [handleIntersection]);

  const deleteFactClick = influencerFactId => {
    if (!disabledActions) {
      dispatch(deleteInfluencerFact({ influencerFactId }))
        .unwrap()
        .then(() => {
          notifyInfo('Influencer fact deleted successfully!');
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  };

  return (
    <Container>
      <Header title="Facts" setShowContent={setShowContent}>
        <StyledButton title="Add New Fact" onClick={() => addNewFactModalRef.current.show()} />
      </Header>
      {pending ? (
        <LoaderContainer>
          <Spinner width={120} height={120} />
        </LoaderContainer>
      ) : (
        <ListContainer>
          {data.map(fact => {
            return (
              <RowItem key={fact.id}>
                <Text14Bold>{fact.text}</Text14Bold>
                <ActionContainer $disabled={disabledActions}>
                  <ActionButton onClick={() => deleteFactClick(fact.id)}>
                    <DeleteIcon color="#BC2D2D" />
                  </ActionButton>
                  <ActionButton
                    onClick={() => {
                      setSelectedFact(fact);
                      addNewFactModalRef.current.show();
                    }}>
                    <EditIcon />
                  </ActionButton>
                </ActionContainer>
              </RowItem>
            );
          })}
          {fetchMorePending && (
            <LoaderMoreContainer>
              <Spinner width={100} height={100} />
            </LoaderMoreContainer>
          )}
          <InfiniteScrollMarker ref={loadMoreRef} />
        </ListContainer>
      )}
      <NewInfluencerFactFormModal
        modalRef={addNewFactModalRef}
        selectedFact={selectedFact}
        setSelectedFact={setSelectedFact}
      />
    </Container>
  );
};

export default FactsPage;
