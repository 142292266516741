import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import {
  Content,
  Navigator,
  NavigatorContent,
  NavigatorItem,
  NavLinkText,
  PageContainer,
  Section,
  SectionTitle,
  Title,
} from './AgencySettingsPage.styles';
import { NavLink, Outlet } from 'react-router-dom';
import { CHANGE_PASSWORD, PERSONAL_INFO, PRIVACY_POLICY, TERMS_AND_CONDITIONS } from '../../../components/App/routes';
import { TransparentButton } from '../../../components';
import { ReactComponent as ChevronIcon } from '../../../assets/icons/chevron_right.svg';
import { logout } from '../../../store/slices/auth/asyncThunks';

const AgencySettingsPage = () => {
  const dispatch = useDispatch();
  const [showContent, setShowContent] = useState(false);
  const smallScreen = useMediaQuery({ minWidth: 0, maxWidth: 768 });

  useEffect(() => {
    setShowContent(!smallScreen);
  }, [smallScreen]);

  return (
    <PageContainer>
      {(!smallScreen || (smallScreen && !showContent)) && (
        <Navigator>
          <NavigatorContent>
            <Title>Settings</Title>
            <Section>
              <SectionTitle>General</SectionTitle>
              <NavigatorItem $smallScreen={smallScreen} onClick={() => setShowContent(true)}>
                <NavLink to={PERSONAL_INFO}>
                  <NavLinkText>Personal Information</NavLinkText>
                  <ChevronIcon />
                </NavLink>
              </NavigatorItem>
              <NavigatorItem $smallScreen={smallScreen} onClick={() => setShowContent(true)}>
                <NavLink to={CHANGE_PASSWORD}>
                  <NavLinkText>Password</NavLinkText>
                  <ChevronIcon />
                </NavLink>
              </NavigatorItem>
            </Section>
            <Section>
              <SectionTitle>Other</SectionTitle>
              <NavigatorItem $smallScreen={smallScreen} onClick={() => setShowContent(true)}>
                <NavLink to={PRIVACY_POLICY}>
                  <NavLinkText>Privacy Policy</NavLinkText>
                  <ChevronIcon />
                </NavLink>
              </NavigatorItem>
              <NavigatorItem $smallScreen={smallScreen} onClick={() => setShowContent(true)}>
                <NavLink to={TERMS_AND_CONDITIONS}>
                  <NavLinkText> Terms & Conditions</NavLinkText>
                  <ChevronIcon />
                </NavLink>
              </NavigatorItem>
            </Section>
          </NavigatorContent>
          <TransparentButton title="Log Out" onClick={() => dispatch(logout())} />
        </Navigator>
      )}
      {showContent && (
        <Content>
          <Outlet context={[setShowContent]} />
        </Content>
      )}
    </PageContainer>
  );
};

export default AgencySettingsPage;
