import React from 'react';
import {
  AddNewButton,
  ButtonText,
  Card,
  Content,
  MethodCard,
  MethodsContainer,
  Text,
  TextContainer,
  TitleContainer,
} from './WithdrawalMethod.styles';
import { Text14Light, Text16Bold, Text16Regular } from '../utils/Text/Text.styles';
import { ReactComponent as CheckCircleEmpty } from '../../assets/icons/check-circle-empty.svg';
import { ReactComponent as CheckCircle } from '../../assets/icons/check-circle-broken.svg';
import { ReactComponent as PlusCircle } from '../../assets/icons/plus-circle.svg';
import { useNavigate } from 'react-router-dom';
import { WITHDRAWAL_METHOD } from '../App/routes';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/user/slice';
import { WITHDRAWAL_METHOD_TYPE } from '../../utils/constants';

const WithdrawalMethod = ({
  title,
  description,
  note,
  isSelected,
  onClick,
  type,
  selectedMethod,
  setSelectedMethod,
}) => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const { crypto, domesticWire, internationalWire } = user.withdrawalMethods;

  const renderMethods = () => {
    switch (type) {
      case WITHDRAWAL_METHOD_TYPE.DOMESTIC_WIRE:
        return domesticWire.map(method => {
          return (
            <MethodCard
              key={method._id}
              onClick={e => {
                e.stopPropagation();
                setSelectedMethod(method._id);
              }}>
              <TextContainer>
                <Text16Regular>{method.beneficiaryName}</Text16Regular>
                <Text14Light>**** Routing Number</Text14Light>
              </TextContainer>
              {selectedMethod === method._id ? <CheckCircle width={24} height={24} /> : <CheckCircleEmpty />}
            </MethodCard>
          );
        });

      case WITHDRAWAL_METHOD_TYPE.INTERNATIONAL_WIRE:
        return internationalWire.map(method => {
          return (
            <MethodCard
              key={method._id}
              onClick={e => {
                e.stopPropagation();
                setSelectedMethod(method._id);
              }}>
              <TextContainer>
                <Text16Regular>{method.beneficiaryName}</Text16Regular>
                <Text14Light>**** IBAN</Text14Light>
              </TextContainer>
              {selectedMethod === method._id ? <CheckCircle width={24} height={24} /> : <CheckCircleEmpty />}
            </MethodCard>
          );
        });
      case WITHDRAWAL_METHOD_TYPE.CRYPTO:
        return crypto.map(method => {
          return (
            <MethodCard
              key={method._id}
              onClick={e => {
                e.stopPropagation();
                setSelectedMethod(method._id);
              }}>
              <TextContainer>
                <Text16Regular>{method.address}</Text16Regular>
                <Text14Light>{method.network.replace('_', ' ')}</Text14Light>
              </TextContainer>
              {selectedMethod === method._id ? <CheckCircle width={24} height={24} /> : <CheckCircleEmpty />}
            </MethodCard>
          );
        });

      default:
        break;
    }
  };

  return (
    <Card onClick={onClick}>
      <TitleContainer $selected={isSelected}>
        {isSelected ? <CheckCircle width={24} height={24} /> : <CheckCircleEmpty />}
        <Text16Bold>{title}</Text16Bold>
      </TitleContainer>
      <Content $selected={isSelected}>
        {isSelected ? (
          <>
            <MethodsContainer>{renderMethods()}</MethodsContainer>
            <AddNewButton
              onClick={() => {
                navigate(`/${WITHDRAWAL_METHOD}`, { state: { type } });
              }}>
              <ButtonText>Add New</ButtonText>
              <PlusCircle />
            </AddNewButton>
          </>
        ) : (
          <>
            <Text14Light>{description}</Text14Light>
            <Text>{note}</Text>
          </>
        )}
      </Content>
    </Card>
  );
};

export default WithdrawalMethod;
