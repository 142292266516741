import styled from 'styled-components';
import { Button } from '../../utils/Button/Button';

export const Form = styled.form`
  padding-bottom: 3rem;
`;

export const StyledButton = styled(Button)`
  width: 23rem;
  margin-inline: auto;
`;
