import styled from 'styled-components';
import { Text12Light, Text13Light, Text14Light } from '../utils/Text/Text.styles';

export const EarningsByMonthContainer = styled.div`
  border-radius: 17px;
  border: 1px solid #f2f2f2;
  box-shadow: 4px 5px 2px 0px rgba(0, 0, 0, 0), 2px 3px 2px 0px rgba(0, 0, 0, 0.01);
  display: flex;
  flex-direction: column;
`;

export const AccordionItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  height: 100%;
`;

export const AccordionItemFooter = styled.div`
  display: flex;
  margin-left: auto;
  gap: 1.8rem;
  width: 29.2rem;
`;

export const FooterTextContainer = styled.div`
  display: flex;
  align-items: center;
  width: 45%;
`;

export const FooterText = styled(Text14Light)`
  opacity: 0.7;
  margin-right: 0.4rem;
`;

export const DollarSign = styled(Text13Light)`
  opacity: 0.3;
  margin-bottom: 0.4rem;
  margin-right: 0.2rem;
`;

export const ItemContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  overflow: auto;
  height: 100%;
`;

export const ItemCard = styled.div`
  border-radius: 1.2rem;
  border: 1px solid #eeecfb;
  background: #fff;
  padding: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;

export const RightContent = styled.div`
  display: flex;
  width: 28rem;
  gap: 1.8rem;
`;

export const DotText = styled(Text12Light)`
  opacity: 0.1;
`;
