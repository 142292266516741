import styled from 'styled-components';
import 'rsuite/dist/rsuite.min.css';
import { theme } from '../../styles/constants';

export const Container = styled.div`
  .rs-picker-input-group {
    background-color: transparent;
    border: 1px solid #dedbf9;
    border-radius: 100px;
    height: 3.8rem;

    &:hover {
      border-color: #dedbf9;
    }

    svg {
      color: white;
    }
  }

  .rs-input-group-addon {
    display: none;
  }

  .rs-picker-input-group {
    background-color: transparent;
    border: 1px solid #dedbf9;

    &:hover {
      border-color: #dedbf9;
    }

    svg {
      color: ${theme.colors.textColor};
    }
  }

  .rs-input-group {
    &:focus-within {
      outline: none;
      border-color: #dedbf9;
    }
  }

  .rs-input-group-focus {
    border: 1px solid #dedbf9;
    outline: none;

    &:hover {
      border-color: #dedbf9;
    }
  }

  .rs-input {
    background-color: transparent;
    color: ${theme.colors.textColor};
    font-weight: 400;
    cursor: pointer;

    &::placeholder {
      color: ${theme.colors.textColor};
      font-weight: 300;
    }
  }
`;
