import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/d/agencies';

class AgencyService {
  changePassword = async ({ currentPassword, password }) => {
    try {
      const body = {
        currentPassword,
        password,
      };
      const response = await axiosApiClient.put(`${BASE_URL}/change-password`, body);
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, "Error occurred while changing user's password.");
    }
  };

  updateBasicInfo = async ({ name }) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/update-basic-info`, { name });
      throwErrorIfWrongStatus(response, 200);
      const { user } = response.data.data;
      return { user };
    } catch (err) {
      throw handleError(err, 'Error occurred while updating basic info.');
    }
  };

  fetchInfluencerEarnings = async ({ influencerId, startDate, endDate }) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/earnings/${influencerId}?startDate=${startDate}&endDate=${endDate}`,
      );
      throwErrorIfWrongStatus(response, 200);
      const { daily, overview, influencerName, earnings } = response.data.data;
      return { daily, overview, influencerName, earnings };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching influencer's earnings.");
    }
  };

  fetchTop10InfluencerEarnings = async ({ startDate, endDate }) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/earnings?startDate=${startDate}&endDate=${endDate}`);
      throwErrorIfWrongStatus(response, 200);
      const { top10Influencers, top10InfluencersAndOthers } = response.data.data;
      return { top10Influencers, top10InfluencersAndOthers };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching influencer's earnings.");
    }
  };

  fetchMonthlyInfluencersEarnings = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/earnings/monthly`);
      throwErrorIfWrongStatus(response, 200);
      const { influencerEarningsByMonth } = response.data.data;
      return { influencerEarningsByMonth };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching influencer's earnings by month.");
    }
  };

  fetchInfluencerEarningsByMonth = async ({ influencerId }) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/earnings/${influencerId}/monthly`);
      throwErrorIfWrongStatus(response, 200);
      const { monthlyInfluencerEarnings } = response.data.data;
      return { monthlyInfluencerEarnings };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching influencer's earnings by month.");
    }
  };

  fetchInfluencerAnalytics = async ({ page, startDate, endDate, sortBy, order }) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/analytics?page=${page}&startDate=${startDate}&endDate=${endDate}&sortBy=${sortBy}&order=${order}`,
      );
      throwErrorIfWrongStatus(response, 200);
      const { data, total } = response.data.data;
      return { data, total };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching influencer's earnings.");
    }
  };
}

export default new AgencyService();
