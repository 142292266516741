import { createSlice } from '@reduxjs/toolkit';
import {
  changePasswordBuilder,
  fetchInfluencerAnalyticsBuilder,
  fetchInfluencerEarningsBuilder,
  fetchInfluencerEarningsByMonthBuilder,
  fetchMonthlyInfluencersEarningsBuilder,
  fetchTop10InfluencerEarningsBuilder,
  updateAgencyBasicInfoBuilder,
} from './builder';
import { LOGOUT } from '../../../utils/constants';

const INIT_STATE = {
  influencerEarnings: null,
  top10InfluencerEarnings: null,
  top10InfluencersAndOthers: null,
  influencerEarningsByMonth: null,
  monthlyInfluencerEarnings: null,
  fetchInfluencerAnalyticsInfo: {
    pending: false,
    data: [],
    total: 0,
  },
  pending: {
    changePassword: false,
    updateAgencyBasicInfo: false,
    fetchInfluencerEarnings: false,
    fetchTop10InfluencerEarnings: false,
    fetchMonthlyInfluencersEarnings: false,
    fetchInfluencerEarningsByMonth: false,
  },
};

export const agencySlice = createSlice({
  name: 'agency',
  initialState: INIT_STATE,
  reducers: {
    setInfluencerEarnings: (state, action) => {
      state.influencerEarnings = action.payload;
    },
  },
  extraReducers: builder => {
    changePasswordBuilder(builder);
    updateAgencyBasicInfoBuilder(builder);
    fetchInfluencerEarningsBuilder(builder);
    fetchTop10InfluencerEarningsBuilder(builder);
    fetchMonthlyInfluencersEarningsBuilder(builder);
    fetchInfluencerEarningsByMonthBuilder(builder);
    fetchInfluencerAnalyticsBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const { setInfluencerEarnings } = agencySlice.actions;

export const selectInfluencerEarnings = state => state.agencyInfo.influencerEarnings;
export const selectTop10InfluencerEarnings = state => state.agencyInfo.top10InfluencerEarnings;
export const selectTop10InfluencersAndOthers = state => state.agencyInfo.top10InfluencersAndOthers;
export const selectInfluencerEarningsByMonth = state => state.agencyInfo.influencerEarningsByMonth;
export const selectMonthlyInfluencerEarnings = state => state.agencyInfo.monthlyInfluencerEarnings;
export const selectFetchInfluencerAnalyticsInfo = state => state.agencyInfo.fetchInfluencerAnalyticsInfo;
export const selectAgencyActionsPending = state => state.agencyInfo.pending;

export default agencySlice.reducer;
