import React from 'react';
import { Form, StyledButton } from './InternationalWireForm.styles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../utils/inputs/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { handleWithdrawalMethods } from '../../../store/slices/user/asyncThunks';
import { notifyError } from '../../../utils/notify';
import { useNavigate } from 'react-router-dom';
import { WITHDRAW } from '../../App/routes';
import { selectUserActionsPending } from '../../../store/slices/user/slice';
import { WITHDRAWAL_METHOD_PROFILE_TYPE, WITHDRAWAL_METHOD_TYPE } from '../../../utils/constants';
import ProfileTypeSwitch from '../ProfileTypeSwitch/ProfileTypeSwitch';
import UploadDocumentPhotoInput from '../../utils/inputs/UploadDocumentPhotoInput/UploadDocumentPhotoInput';
import { ReactComponent as IdCardFront } from '../../../assets/icons/id-front.svg';

export const internationalWireFormSchema = yup
  .object({
    swiftCode: yup.string().trim().required('Swift Code is required'),
    bankName: yup.string().trim().required('Bank Name is required'),
    beneficiaryName: yup.string().trim().required('Beneficiary Name is required'),
    beneficiaryIBAN: yup.string().trim().required('Beneficiary IBAN is required'),
    beneficiaryAddress: yup.string().trim().required('Beneficiary Address is required'),
    ssn: yup
      .string()
      .trim()
      .when('profileType', {
        is: WITHDRAWAL_METHOD_PROFILE_TYPE.PERSONAL,
        then: schema => schema.required('Social Security Number is required'),
      }),
    verificationDocumentFront: yup.mixed().required('Passport is required'),
  })
  .required();

const InternationalWireForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleWithdrawalMethods: handleWithdrawalMethodsPending } = useSelector(selectUserActionsPending);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      swiftCode: '',
      bankName: '',
      beneficiaryName: '',
      beneficiaryIBAN: '',
      beneficiaryAddress: '',
      ssn: '',
      verificationDocumentFront: null,
      profileType: WITHDRAWAL_METHOD_PROFILE_TYPE.PERSONAL,
    },
    delayError: 300,
    resolver: yupResolver(internationalWireFormSchema),
  });

  const profileTypeWatch = watch('profileType');

  const onSubmit = async data => {
    dispatch(handleWithdrawalMethods({ ...data, withdrawalMethodType: WITHDRAWAL_METHOD_TYPE.INTERNATIONAL_WIRE }))
      .unwrap()
      .then(() => {
        navigate(`/${WITHDRAW}`);
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="profileType"
        control={control}
        render={({ field }) => {
          return <ProfileTypeSwitch value={field.value} onChange={field.onChange} />;
        }}
      />
      <Input
        label={profileTypeWatch === WITHDRAWAL_METHOD_PROFILE_TYPE.PERSONAL ? 'Beneficiary Name' : 'Business Name'}
        placeholder={
          profileTypeWatch === WITHDRAWAL_METHOD_PROFILE_TYPE.PERSONAL
            ? 'Enter Beneficiary Name'
            : 'Enter Business Name'
        }
        {...register('beneficiaryName')}
        error={errors.beneficiaryName?.message}
      />
      <Input
        label={
          profileTypeWatch === WITHDRAWAL_METHOD_PROFILE_TYPE.PERSONAL ? 'Beneficiary Address' : 'Business Address'
        }
        placeholder={
          profileTypeWatch === WITHDRAWAL_METHOD_PROFILE_TYPE.PERSONAL
            ? 'Enter Beneficiary Address'
            : 'Enter Business Address'
        }
        {...register('beneficiaryAddress')}
        error={errors.beneficiaryAddress?.message}
      />
      {profileTypeWatch === WITHDRAWAL_METHOD_PROFILE_TYPE.PERSONAL && (
        <Input
          label="Social Security Number"
          placeholder="Enter Social Security Number"
          {...register('ssn')}
          error={errors.ssn?.message}
        />
      )}
      <Input
        label="Bank Name"
        placeholder="Enter Bank Name"
        {...register('bankName')}
        error={errors.bankName?.message}
      />
      <Input
        label="Bank Swift Code"
        placeholder="Enter Bank Swift Code"
        {...register('swiftCode')}
        error={errors.swiftCode?.message}
      />
      <Input
        label="IBAN"
        placeholder="Enter IBAN"
        {...register('beneficiaryIBAN')}
        error={errors.beneficiaryIBAN?.message}
      />
      <Controller
        name="verificationDocumentFront"
        control={control}
        render={({ field }) => {
          return (
            <UploadDocumentPhotoInput
              value={field.value}
              onChange={field.onChange}
              error={errors.verificationDocumentFront?.message}
              title="Passport"
              icon={<IdCardFront />}
            />
          );
        }}
      />
      <StyledButton title="Save" type="submit" isLoading={handleWithdrawalMethodsPending} />
    </Form>
  );
};

export default InternationalWireForm;
