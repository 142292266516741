import React from 'react';
import { useSelector } from 'react-redux';

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AGENCY_HOME, FORBIDDEN, HOME, UPDATE_PASSWORD } from '../App/routes';
import { selectUser } from '../../store/slices/user/slice';
import { USER_ROLE } from '../../utils/constants';

const PrivateOutlet = ({ roles }) => {
  const user = useSelector(selectUser);
  const { pathname } = useLocation();

  if (!roles.includes(user.role)) {
    if (pathname === HOME) {
      if (user.role === USER_ROLE.INFLUENCER_REQUEST) {
        return <Navigate to={`/${UPDATE_PASSWORD}`} />;
      } else {
        return <Navigate to={`/${AGENCY_HOME}`} />;
      }
    }
    return <Navigate to={`/${FORBIDDEN}`} />;
  }

  return <Outlet />;
};

export default PrivateOutlet;
