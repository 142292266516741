import { createAsyncThunk } from '@reduxjs/toolkit';
import InfluencerFactService from '../../../api/services/InfluencerFactService';

export const fetchInfluencerFacts = createAsyncThunk(
  'influencerFact/fetchInfluencerFacts',
  async ({ page, search }) => {
    return await InfluencerFactService.fetchInfluencerFacts(page, search);
  },
);

export const fetchAgencyInfluencerFacts = createAsyncThunk(
  'influencerFact/fetchAgencyInfluencerFacts',
  async ({ page, search, influencerId }) => {
    return await InfluencerFactService.fetchAgencyInfluencerFacts(page, search, influencerId);
  },
);

export const createInfluencerFact = createAsyncThunk('influencerFact/createInfluencerFact', async ({ text }) => {
  return await InfluencerFactService.createInfluencerFact(text);
});

export const agencyCreateInfluencerFact = createAsyncThunk(
  'influencerFact/agencyCreateInfluencerFact',
  async ({ text, influencerId }) => {
    return await InfluencerFactService.agencyCreateInfluencerFact(text, influencerId);
  },
);

export const deleteInfluencerFact = createAsyncThunk(
  'influencerFact/deleteInfluencerFact',
  async ({ influencerFactId }) => {
    return await InfluencerFactService.deleteInfluencerFact(influencerFactId);
  },
);

export const deleteAgencyInfluencerFact = createAsyncThunk(
  'influencerFact/deleteAgencyInfluencerFact',
  async ({ influencerFactId }) => {
    return await InfluencerFactService.deleteAgencyInfluencerFact(influencerFactId);
  },
);

export const editInfluencerFact = createAsyncThunk(
  'influencerFact/editInfluencerFact',
  async ({ influencerFactId, text }) => {
    return await InfluencerFactService.editInfluencerFact(influencerFactId, text);
  },
);

export const agencyEditInfluencerFact = createAsyncThunk(
  'influencerFact/agencyEditInfluencerFact',
  async ({ influencerFactId, text }) => {
    return await InfluencerFactService.agencyEditInfluencerFact(influencerFactId, text);
  },
);
