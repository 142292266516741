import {
  agencyCreateInfluencerFact,
  agencyEditInfluencerFact,
  createInfluencerFact,
  deleteAgencyInfluencerFact,
  deleteInfluencerFact,
  editInfluencerFact,
  fetchAgencyInfluencerFacts,
  fetchInfluencerFacts,
} from './asyncThunks';

export const fetchInfluencerFactsBuilder = builder => {
  builder.addCase(fetchInfluencerFacts.pending, (state, { meta }) => {
    const page = meta.arg.page;
    if (page === 1) {
      state.fetchInfluencerFactsInfo.pending = true;
    } else {
      state.fetchInfluencerFactsInfo.fetchMorePending = true;
    }
  });
  builder.addCase(fetchInfluencerFacts.fulfilled, (state, { payload }) => {
    const { data, maxReached, page } = payload;
    if (page === 1) {
      state.fetchInfluencerFactsInfo.data = data;
      state.fetchInfluencerFactsInfo.pending = false;
    } else {
      state.fetchInfluencerFactsInfo.fetchMorePending = false;
      state.fetchInfluencerFactsInfo.data = [...state.fetchInfluencerFactsInfo.data, ...data];
    }
    state.fetchInfluencerFactsInfo.maxReached = maxReached;
  });
  builder.addCase(fetchInfluencerFacts.rejected, state => {
    state.fetchInfluencerFactsInfo.pending = false;
    state.fetchInfluencerFactsInfo.fetchMorePending = false;
  });
};

export const fetchAgencyInfluencerFactsBuilder = builder => {
  builder.addCase(fetchAgencyInfluencerFacts.pending, (state, { meta }) => {
    const page = meta.arg.page;
    if (page === 1) {
      state.fetchInfluencerFactsInfo.pending = true;
    } else {
      state.fetchInfluencerFactsInfo.fetchMorePending = true;
    }
  });
  builder.addCase(fetchAgencyInfluencerFacts.fulfilled, (state, { payload }) => {
    const { data, maxReached, page } = payload;
    if (page === 1) {
      state.fetchInfluencerFactsInfo.data = data;
      state.fetchInfluencerFactsInfo.pending = false;
    } else {
      state.fetchInfluencerFactsInfo.fetchMorePending = false;
      state.fetchInfluencerFactsInfo.data = [...state.fetchInfluencerFactsInfo.data, ...data];
    }
    state.fetchInfluencerFactsInfo.maxReached = maxReached;
  });
  builder.addCase(fetchAgencyInfluencerFacts.rejected, state => {
    state.fetchInfluencerFactsInfo.pending = false;
    state.fetchInfluencerFactsInfo.fetchMorePending = false;
  });
};

export const createInfluencerFactBuilder = builder => {
  builder.addCase(createInfluencerFact.pending, state => {
    state.pending.createInfluencerFact = true;
  });
  builder.addCase(createInfluencerFact.fulfilled, (state, { payload }) => {
    const { influencerFact } = payload;
    state.pending.createInfluencerFact = false;
    state.fetchInfluencerFactsInfo.data = [influencerFact, ...state.fetchInfluencerFactsInfo.data];
  });
  builder.addCase(createInfluencerFact.rejected, state => {
    state.pending.createInfluencerFact = false;
  });
};

export const agencyCreateInfluencerFactBuilder = builder => {
  builder.addCase(agencyCreateInfluencerFact.pending, state => {
    state.pending.createInfluencerFact = true;
  });
  builder.addCase(agencyCreateInfluencerFact.fulfilled, (state, { payload }) => {
    const { influencerFact } = payload;
    state.pending.createInfluencerFact = false;
    state.fetchInfluencerFactsInfo.data = [influencerFact, ...state.fetchInfluencerFactsInfo.data];
  });
  builder.addCase(agencyCreateInfluencerFact.rejected, state => {
    state.pending.createInfluencerFact = false;
  });
};

export const deleteInfluencerFactBuilder = builder => {
  builder.addCase(deleteInfluencerFact.pending, state => {
    state.pending.deleteInfluencerFact = true;
  });
  builder.addCase(deleteInfluencerFact.fulfilled, (state, { payload }) => {
    const { influencerFactId } = payload;
    state.pending.deleteInfluencerFact = false;
    state.fetchInfluencerFactsInfo.data = state.fetchInfluencerFactsInfo.data.filter(d => d.id !== influencerFactId);
  });
  builder.addCase(deleteInfluencerFact.rejected, state => {
    state.pending.deleteInfluencerFact = false;
  });
};

export const deleteAgencyInfluencerFactBuilder = builder => {
  builder.addCase(deleteAgencyInfluencerFact.pending, state => {
    state.pending.deleteInfluencerFact = true;
  });
  builder.addCase(deleteAgencyInfluencerFact.fulfilled, (state, { payload }) => {
    const { influencerFactId } = payload;
    state.pending.deleteInfluencerFact = false;
    state.fetchInfluencerFactsInfo.data = state.fetchInfluencerFactsInfo.data.filter(d => d.id !== influencerFactId);
  });
  builder.addCase(deleteAgencyInfluencerFact.rejected, state => {
    state.pending.deleteInfluencerFact = false;
  });
};

export const editInfluencerFactBuilder = builder => {
  builder.addCase(editInfluencerFact.pending, state => {
    state.pending.editInfluencerFact = true;
  });
  builder.addCase(editInfluencerFact.fulfilled, (state, { payload }) => {
    const { influencerFact } = payload;
    state.pending.editInfluencerFact = false;
    state.fetchInfluencerFactsInfo.data = state.fetchInfluencerFactsInfo.data.map(d =>
      d.id === influencerFact.id ? influencerFact : d,
    );
  });
  builder.addCase(editInfluencerFact.rejected, state => {
    state.pending.editInfluencerFact = false;
  });
};

export const agencyEditInfluencerFactBuilder = builder => {
  builder.addCase(agencyEditInfluencerFact.pending, state => {
    state.pending.editInfluencerFact = true;
  });
  builder.addCase(agencyEditInfluencerFact.fulfilled, (state, { payload }) => {
    const { influencerFact } = payload;
    state.pending.editInfluencerFact = false;
    state.fetchInfluencerFactsInfo.data = state.fetchInfluencerFactsInfo.data.map(d =>
      d.id === influencerFact.id ? influencerFact : d,
    );
  });
  builder.addCase(agencyEditInfluencerFact.rejected, state => {
    state.pending.editInfluencerFact = false;
  });
};
