import React from 'react';
import {
  CloseIconWrapper,
  Container,
  Image,
  ImageContainer,
  Text,
  Title,
  TitleContainer,
  UploadFileInput,
  UploadFileInputContainer,
} from './UploadDocumentPhotoInput.styles';
import ErrorContainer from '../../ErrorContainer/ErrorContainer';
import { UPLOAD_FILE_CONSTRAINTS } from '../../../../utils/constants';
import { notifyError } from '../../../../utils/notify';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';

const UploadDocumentPhotoInput = ({ value, onChange, error, icon, title }) => {
  const validateFile = file => {
    const allowedExtensions = UPLOAD_FILE_CONSTRAINTS.PHOTO.ALLOWED_EXTENSIONS;
    const maxFileSizeInBytes = UPLOAD_FILE_CONSTRAINTS.PHOTO.MAX_SIZE_IN_BYTES;
    if (file.size < maxFileSizeInBytes) {
      const fileType = file.type.split('/')[1];
      if (allowedExtensions.includes(fileType)) {
        return file;
      } else {
        notifyError('Unsupported type');
      }
    } else {
      notifyError('File size error');
    }
  };

  const acceptedExtensionsText = UPLOAD_FILE_CONSTRAINTS.PHOTO.ALLOWED_EXTENSIONS_TEXT;

  return (
    <Container>
      <UploadFileInputContainer $hasValue={!!value}>
        <UploadFileInput
          type="file"
          title=""
          value=""
          onChange={e => {
            const { files: newFiles } = e.target;
            if (newFiles.length) {
              const file = validateFile(newFiles[0]);
              if (file) {
                onChange({ file, url: URL.createObjectURL(file) });
              }
            }
          }}
          accept={acceptedExtensionsText}
        />
        {value ? (
          <ImageContainer>
            <CloseIconWrapper onClick={() => onChange(null)}>
              <CloseIcon width={14} height={14} color="#fff" />
            </CloseIconWrapper>
            <Image src={typeof value === 'string' ? value : value.url} />
          </ImageContainer>
        ) : (
          <>
            <TitleContainer>
              {icon}
              <Title>{title}</Title>
            </TitleContainer>
            <Text>Supported Files: .jpeg, .png, .heic</Text>
          </>
        )}
      </UploadFileInputContainer>
      <ErrorContainer errorText={error} />
    </Container>
  );
};

export default UploadDocumentPhotoInput;
