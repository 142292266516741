/**
 * Редирект страницы на заданный URL.
 *
 * @param {string} url - URL.
 * @param {Object} [options={}] - Объект дополнительных настроек.
 * @param {'parent'|'self'|'top'} [options.target='top'] - Целевое окно.
 * @returns {undefined}
 */

const DEFAULT_TARGET = 'top';
const allowedTargets = ['parent', 'self', 'top'];

export function redirect(url, options = {}) {
  const { target = 'top' } = options;

  const resultTarget = allowedTargets.includes(target)
    ? target
    : DEFAULT_TARGET;

  const {
    [resultTarget]: { location },
  } = window;

  location.href = url;
}
