import React, { useEffect, useState } from 'react';
import { Content, PageContainer, TitleContainer, TitleRightSide } from './AgencyInfluencersPage.styles';
import { Text24Bold } from '../../../components/utils/Text/Text.styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DateRangePickerComponent, InfluencerItem, Select, Table } from '../../../components';
import { AGENCY_INFLUENCERS } from '../../../components/App/routes';
import { ORDER } from '../../../components/Table/Table';
import { fetchInfluencerAnalytics } from '../../../store/slices/agency/asyncThunks';
import { selectFetchInfluencerAnalyticsInfo } from '../../../store/slices/agency/slice';
import { EARNINGS_TYPE, VALUE_OF_ONE_STAR_IN_USD } from '../../../utils/constants';
import { selectDateRange, selectEarningType, setEarningType } from '../../../store/slices/user/slice';

const AgencyInfluencersPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +searchParams.get('page') || 1;
  const range = useSelector(selectDateRange);
  const earningType = useSelector(selectEarningType);
  const { data, total, pending } = useSelector(selectFetchInfluencerAnalyticsInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sortOptions, setSortOptions] = useState({
    sortBy: '',
    order: ORDER.ASC,
  });

  useEffect(() => {
    if (page > 0) {
      dispatch(
        fetchInfluencerAnalytics({
          page,
          startDate: range[0],
          endDate: range[1],
          sortBy: sortOptions.sortBy,
          order: sortOptions.order,
        }),
      );
    }
  }, [dispatch, page, sortOptions, range]);

  const setPage = page => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  };

  const tableHeader = [
    { label: 'Name', sortable: true, key: 'influencerName' },
    { label: 'Subscriptions', sortable: true, key: 'totalSubscriptionEarnings' },
    { label: 'Content Earnings', sortable: true, key: 'totalContentEarnings' },
    { label: 'Total Earnings', sortable: true, key: 'totalEarnings' },
    { type: 'action' },
  ];
  const multiplier = earningType.value === EARNINGS_TYPE.NET ? 1 : 2;
  return (
    <PageContainer>
      <TitleContainer>
        <Text24Bold>Creators</Text24Bold>
        <TitleRightSide>
          <DateRangePickerComponent />
          <Select
            options={[
              { value: EARNINGS_TYPE.GROSS, label: 'Gross' },
              { value: EARNINGS_TYPE.NET, label: 'Net' },
            ]}
            handleChange={value => dispatch(setEarningType(value))}
            selectedOption={earningType}
            hideError
          />
        </TitleRightSide>
      </TitleContainer>
      <Content>
        <Table
          headerInformation={tableHeader}
          dataLoading={pending}
          totalCount={total}
          currentPage={page}
          perPage={10}
          setPage={setPage}
          emptyDataText={'No data'}
          sortOptions={sortOptions}
          setSortOptions={setSortOptions}
          fullHeight>
          {data.map(item => (
            <InfluencerItem
              key={item.influencer}
              name={item.influencerName}
              profilePhoto={item.influencerPhoto}
              totalSubscriptionEarnings={item.totalSubscriptionEarnings * VALUE_OF_ONE_STAR_IN_USD * multiplier}
              totalContentEarnings={item.totalContentEarnings * VALUE_OF_ONE_STAR_IN_USD * multiplier}
              totalEarnings={item.totalEarnings * VALUE_OF_ONE_STAR_IN_USD * multiplier}
              onDetailsClicked={() => navigate(`/${AGENCY_INFLUENCERS}/${item.influencer}`)}
            />
          ))}
        </Table>
      </Content>
    </PageContainer>
  );
};

export default AgencyInfluencersPage;
