import { createAsyncThunk } from '@reduxjs/toolkit';
import UserService from '../../../api/services/UserService';
import { base64ToFile } from '../../../utils/util';

export const fetchUserInfo = createAsyncThunk('user/fetchUserInfo', async () => {
  return await UserService.fetchUserInfo();
});

export const changePassword = createAsyncThunk('user/changePassword', async requestData => {
  return await UserService.changePassword(requestData);
});

export const fetchUserEarnings = createAsyncThunk('user/fetchUserEarnings', async requestData => {
  return await UserService.fetchUserEarnings(requestData);
});

export const handleWithdrawalMethods = createAsyncThunk('user/handleWithdrawalMethods', async requestData => {
  const {
    verificationDocumentFront,
    verificationDocumentBack,
    accountNumber,
    routingNumber,
    beneficiaryName,
    beneficiaryAddress,
    ssn,
    profileType,
    swiftCode,
    bankName,
    beneficiaryIBAN,
    address,
    network,
    withdrawalMethodType,
  } = requestData;

  const formData = new FormData();

  if (verificationDocumentFront) {
    formData.append('verificationDocumentFront', verificationDocumentFront.file);
  }
  if (verificationDocumentBack) {
    formData.append('verificationDocumentBack', verificationDocumentBack.file);
  }
  if (accountNumber) {
    formData.append('accountNumber', accountNumber);
  }
  if (routingNumber) {
    formData.append('routingNumber', routingNumber);
  }
  if (beneficiaryName) {
    formData.append('beneficiaryName', beneficiaryName);
  }
  if (beneficiaryAddress) {
    formData.append('beneficiaryAddress', beneficiaryAddress);
  }
  if (ssn) {
    formData.append('ssn', ssn);
  }
  if (profileType) {
    formData.append('profileType', profileType);
  }
  if (swiftCode) {
    formData.append('swiftCode', swiftCode);
  }
  if (bankName) {
    formData.append('bankName', bankName);
  }
  if (beneficiaryIBAN) {
    formData.append('beneficiaryIBAN', beneficiaryIBAN);
  }
  if (address) {
    formData.append('address', address);
  }
  if (network) {
    formData.append('network', network);
  }
  formData.append('withdrawalMethodType', withdrawalMethodType);

  return await UserService.handleWithdrawalMethods(formData);
});

export const updateUserBasicInfo = createAsyncThunk('user/updateUserBasicInfo', async requestData => {
  const { name, dateOfBirth, profilePhoto } = requestData;

  const formData = new FormData();
  if (profilePhoto) {
    const profilePhotoFile = base64ToFile(profilePhoto, 'profile-photo');
    formData.append('file', profilePhotoFile, profilePhotoFile.name);
  }

  formData.append('name', name);
  formData.append('dateOfBirth', dateOfBirth);

  return await UserService.updateBasicInfo(formData);
});

export const fetchInfluencers = createAsyncThunk('user/fetchInfluencers', async requestData => {
  return await UserService.fetchInfluencers(requestData);
});

export const fetchUserMonthlyEarnings = createAsyncThunk('user/fetchUserMonthlyEarnings', async () => {
  return await UserService.fetchUserMonthlyEarnings();
});
