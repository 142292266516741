import React, { useMemo } from 'react';
import { Container } from './AgencyHomeBarChart.styles';
import { theme } from '../../styles/constants';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { EARNINGS_TYPE, VALUE_OF_ONE_STAR_IN_USD } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { selectEarningType } from '../../store/slices/user/slice';

const AgencyHomeBarChart = ({ chartData }) => {
  const earningType = useSelector(selectEarningType);

  const data = useMemo(() => {
    const influencers = Object.keys(chartData);

    const multiplier = earningType.value === EARNINGS_TYPE.NET ? 1 : 2;

    return influencers.map(day => {
      const photos = chartData[day].photo.earnings * VALUE_OF_ONE_STAR_IN_USD * multiplier;
      const videos = chartData[day].video.earnings * VALUE_OF_ONE_STAR_IN_USD * multiplier;
      const bundles = chartData[day].bundle.earnings * VALUE_OF_ONE_STAR_IN_USD * multiplier;
      const subscription = chartData[day].subscription.earnings * VALUE_OF_ONE_STAR_IN_USD * multiplier;
      const other = chartData[day].other.earnings * VALUE_OF_ONE_STAR_IN_USD * multiplier;
      return {
        name: influencers,
        data: [photos, videos, bundles, subscription, other],
      };
    });
  }, [chartData, earningType]);

  const colors = ['#DDCEFF', '#AD88EF', '#7E4FD0', '#572E9F', '#311D5C'];

  const categories = useMemo(() => {
    const influencers = Object.keys(chartData);

    if (influencers.length < 10) {
      return [...influencers, ...Array(10 - influencers.length).fill('')];
    }
    return influencers;
  }, [chartData]);

  const options = {
    chart: {
      type: 'bar',
      backgroundColor: 'transparent',
      style: {
        fontFamily: 'Lexend',
      },
    },
    title: {
      text: '',
    },
    accessibility: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    legend: {
      itemStyle: {
        color: theme.colors.textColor,
        fontSize: 12,
      },
    },
    xAxis: {
      max: 9,
      categories,
      labels: {
        style: {
          color: theme.colors.textColor,
          fontSize: 11,
        },
      },
      gridLineColor: '#E0E0E0',
      gridLineDashStyle: 'Dot',
      gridLineWidth: 1,
    },
    yAxis: {
      title: {
        text: '',
      },
      gridLineColor: '#E0E0E0',
      gridLineDashStyle: 'Dot',
      labels: {
        formatter: function ({ value }) {
          return `$${value}`;
        },
        style: {
          color: theme.colors.textColor,
          fontSize: 12,
        },
      },
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
        borderWidth: 0,
        borderRadius: 5,
        pointWidth: 22,
      },
    },
    colors: colors,
    tooltip: {
      valueDecimals: 2,
      shared: true,
      style: {
        fontSize: 12,
      },
    },
    series: [
      {
        name: 'Photos',
        data: data.map(item => item.data[0]),
      },
      {
        name: 'Videos',
        data: data.map(item => item.data[1]),
      },
      {
        name: 'Bundles',
        data: data.map(item => item.data[2]),
      },
      {
        name: 'Subscriptions',
        data: data.map(item => item.data[3]),
      },
      {
        name: 'Other',
        data: data.map(item => item.data[4]),
      },
    ],
  };

  return (
    <Container>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Container>
  );
};

export default AgencyHomeBarChart;
