import React from 'react';
import { Button, ButtonLabel, Container } from './ProfileTypeSwitch.styles';
import { WITHDRAWAL_METHOD_PROFILE_TYPE } from '../../../utils/constants';

const ProfileTypeSwitch = ({ value, onChange }) => {
  return (
    <Container>
      <Button
        $active={value === WITHDRAWAL_METHOD_PROFILE_TYPE.PERSONAL}
        onClick={() => onChange(WITHDRAWAL_METHOD_PROFILE_TYPE.PERSONAL)}>
        <ButtonLabel $active={value === WITHDRAWAL_METHOD_PROFILE_TYPE.PERSONAL}>Personal</ButtonLabel>
      </Button>
      <Button
        $active={value === WITHDRAWAL_METHOD_PROFILE_TYPE.BUSINESS}
        onClick={() => onChange(WITHDRAWAL_METHOD_PROFILE_TYPE.BUSINESS)}>
        <ButtonLabel $active={value === WITHDRAWAL_METHOD_PROFILE_TYPE.BUSINESS}>Business</ButtonLabel>
      </Button>
    </Container>
  );
};

export default ProfileTypeSwitch;
