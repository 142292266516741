import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ActionButton,
  ActionContainer,
  Container,
  HeaderContainer,
  InfiniteScrollMarker,
  ListContainer,
  LoaderContainer,
  LoaderMoreContainer,
  RowItem,
  StyledButton,
  StyledInput,
} from './InfluencerFactsPage.styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFetchInfluencerFactsInfo,
  selectInfluencerFactActionsPending,
} from '../../../store/slices/influencerFact/slice';
import {
  deleteAgencyInfluencerFact,
  fetchAgencyInfluencerFacts,
} from '../../../store/slices/influencerFact/asyncThunks';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { useParams } from 'react-router-dom';
import { NewInfluencerFactFormModal, Spinner } from '../../../components';
import { Text14Bold } from '../../../components/utils/Text/Text.styles';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/pencil.svg';
import debounce from 'lodash.debounce';

const InfluencerFactsPage = () => {
  const { id: influencerId } = useParams();
  const dispatch = useDispatch();
  const loadMoreRef = useRef(null);
  const addNewFactModalRef = useRef(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { data, maxReached, pending, fetchMorePending } = useSelector(selectFetchInfluencerFactsInfo);
  const { deleteInfluencerFact: deleteInfluencerFactPending } = useSelector(selectInfluencerFactActionsPending);
  const [selectedFact, setSelectedFact] = useState(null);

  const changeHandler = useCallback(text => {
    setSearch(text);
  }, []);

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  const disabledActions = deleteInfluencerFactPending;

  useEffect(() => {
    setPage(1);
    dispatch(fetchAgencyInfluencerFacts({ page: 1, search, influencerId }));
  }, [dispatch, influencerId, search]);

  const handleIntersection = useCallback(
    entries => {
      const [entry] = entries;
      if (entry.isIntersecting && !pending && !fetchMorePending && !maxReached) {
        setPage(prev => prev + 1);
        dispatch(fetchAgencyInfluencerFacts({ page: page + 1, search, influencerId }));
      }
    },
    [pending, maxReached, fetchMorePending, page, dispatch, influencerId, search],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, { root: null, threshold: 1.0 });
    const target = loadMoreRef.current;
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [handleIntersection]);

  const deleteFactClick = influencerFactId => {
    if (!disabledActions) {
      dispatch(deleteAgencyInfluencerFact({ influencerFactId }))
        .unwrap()
        .then(() => {
          notifyInfo('Influencer fact deleted successfully!');
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  };
  return (
    <Container>
      <HeaderContainer>
        <StyledInput
          onChange={event => {
            debouncedChangeHandler(event.target.value);
          }}
          placeholder="Search Facts"
          hideErrorMessage
        />
        <StyledButton onClick={() => addNewFactModalRef.current.show()} title="Add New Fact" />
      </HeaderContainer>
      {pending ? (
        <LoaderContainer>
          <Spinner width={120} height={120} />
        </LoaderContainer>
      ) : (
        <ListContainer>
          {data.map(fact => {
            return (
              <RowItem key={fact.id}>
                <Text14Bold>{fact.text}</Text14Bold>
                <ActionContainer $disabled={disabledActions}>
                  <ActionButton onClick={() => deleteFactClick(fact.id)}>
                    <DeleteIcon color="#BC2D2D" />
                  </ActionButton>
                  <ActionButton
                    onClick={() => {
                      setSelectedFact(fact);
                      addNewFactModalRef.current.show();
                    }}>
                    <EditIcon />
                  </ActionButton>
                </ActionContainer>
              </RowItem>
            );
          })}
          {fetchMorePending && (
            <LoaderMoreContainer>
              <Spinner width={100} height={100} />
            </LoaderMoreContainer>
          )}
          <InfiniteScrollMarker ref={loadMoreRef} />
        </ListContainer>
      )}
      <NewInfluencerFactFormModal
        influencerId={influencerId}
        modalRef={addNewFactModalRef}
        selectedFact={selectedFact}
        setSelectedFact={setSelectedFact}
      />
    </Container>
  );
};

export default InfluencerFactsPage;
