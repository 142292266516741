import React, { useEffect } from 'react';
import {
  AnalyticsContainer,
  ChartContainer,
  ChartContent,
  ChartHeader,
  Content,
  Line,
  PageContainer,
  SpinnerContainer,
  StatisticContainer,
  TitleRightSide,
  TotalContainer,
} from './HomePage.styles';
import { Text14Regular, Text18Bold, Text24Bold } from '../../components/utils/Text/Text.styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDateRange,
  selectEarningType,
  selectMonthlyInfluencerEarnings,
  selectUser,
  selectUserActionsPending,
  setEarningType,
} from '../../store/slices/user/slice';
import {
  AnalyticsCard,
  DateRangePickerComponent,
  HomeBarChart,
  InfluencerEarningsByMonth,
  Select,
  Spinner,
  StatisticCard,
} from '../../components';
import { ReactComponent as WalletIcon } from '../../assets/icons/wallet.svg';
import { ReactComponent as SafeIcon } from '../../assets/icons/safe.svg';
import { ReactComponent as CreditCardIcon } from '../../assets/icons/credit-card-down.svg';
import { ReactComponent as ImageIcon } from '../../assets/icons/image.svg';
import { ReactComponent as VideoRecorderIcon } from '../../assets/icons/video-recorder.svg';
import { ReactComponent as SaleIcon } from '../../assets/icons/sale.svg';
import { ReactComponent as FilmIcon } from '../../assets/icons/film.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { EARNINGS_TYPE, VALUE_OF_ONE_STAR_IN_USD } from '../../utils/constants';
import { fetchUserEarnings, fetchUserMonthlyEarnings } from '../../store/slices/user/asyncThunks';
import { DollarSign, EarningContainer, NetText } from '../../components/home/AnalyticsCard/AnalyticsCard.styles';
import { formatPrice } from '../../utils/util';

const HomePage = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const range = useSelector(selectDateRange);
  const earningType = useSelector(selectEarningType);
  const { fetchUserEarnings: fetchUserEarningsPending, fetchUserMonthlyEarnings: fetchUserMonthlyEarningsPending } =
    useSelector(selectUserActionsPending);
  const monthlyInfluencerEarnings = useSelector(selectMonthlyInfluencerEarnings);
  const { earnings, daily, overview } = user.analytics;

  useEffect(() => {
    dispatch(fetchUserEarnings({ startDate: range[0], endDate: range[1] }));
  }, [range, dispatch]);

  useEffect(() => {
    dispatch(fetchUserMonthlyEarnings());
  }, [dispatch]);

  const totalEarnings = overview
    ? overview.photo.earnings +
      overview.video.earnings +
      overview.bundle.earnings +
      overview.subscription.earnings +
      overview.other.earnings
    : 0;

  return (
    <PageContainer>
      <Text24Bold>{`Welcome, ${user.name}!`}</Text24Bold>
      <Content>
        <StatisticContainer>
          <StatisticCard
            title="Available Balance"
            icon={<CreditCardIcon />}
            value={earnings.available * VALUE_OF_ONE_STAR_IN_USD}
            valuePrefix="$"
            hideGross
          />
          <StatisticCard
            title="Pending Balance"
            icon={<WalletIcon color="#BC9EFF" />}
            value={(earnings.balance - earnings.available) * VALUE_OF_ONE_STAR_IN_USD}
            valuePrefix="$"
            hideGross
          />
          <StatisticCard
            title="Lifetime Proceeds"
            icon={<SafeIcon />}
            value={earnings.lifetime * VALUE_OF_ONE_STAR_IN_USD}
            valuePrefix="$"
          />
        </StatisticContainer>
        <ChartContainer>
          <ChartHeader>
            <Text18Bold>Overview</Text18Bold>
            <TitleRightSide>
              <DateRangePickerComponent />
              <Select
                options={[
                  { value: EARNINGS_TYPE.GROSS, label: 'Gross' },
                  { value: EARNINGS_TYPE.NET, label: 'Net' },
                ]}
                handleChange={value => dispatch(setEarningType(value))}
                selectedOption={earningType}
                hideError
              />
            </TitleRightSide>
          </ChartHeader>
          {daily && overview && !fetchUserEarningsPending ? (
            <ChartContent>
              <HomeBarChart dailyAnalytics={daily} />
              <Line />
              <AnalyticsContainer>
                <AnalyticsCard icon={<ImageIcon />} title="Photo Sales" earnings={overview.photo.earnings} />
                <AnalyticsCard icon={<VideoRecorderIcon />} title="Video Sales" earnings={overview.video.earnings} />
                <AnalyticsCard icon={<FilmIcon />} title="Bundle Sales" earnings={overview.bundle.earnings} />
                <AnalyticsCard
                  icon={<StarIcon />}
                  title="Subscription Sales"
                  earnings={overview.subscription.earnings}
                />
                <AnalyticsCard icon={<SaleIcon />} title="Other Sales" earnings={overview.other.earnings} />
                <TotalContainer>
                  <EarningContainer>
                    <NetText>Total Net:</NetText>
                    <DollarSign>$</DollarSign>
                    <Text14Regular>{formatPrice(totalEarnings * VALUE_OF_ONE_STAR_IN_USD)}</Text14Regular>
                  </EarningContainer>
                  <EarningContainer>
                    <NetText>Total Gross:</NetText>
                    <DollarSign>$</DollarSign>
                    <Text18Bold>{formatPrice(totalEarnings * VALUE_OF_ONE_STAR_IN_USD * 2)}</Text18Bold>
                  </EarningContainer>
                </TotalContainer>
              </AnalyticsContainer>
            </ChartContent>
          ) : (
            <SpinnerContainer>
              <Spinner width={150} height={150} />
            </SpinnerContainer>
          )}
        </ChartContainer>
        <InfluencerEarningsByMonth
          monthlyInfluencerEarnings={monthlyInfluencerEarnings}
          pending={fetchUserMonthlyEarningsPending}
        />
      </Content>
    </PageContainer>
  );
};

export default HomePage;
