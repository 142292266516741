import styled from 'styled-components';
import { Button, TransparentButton } from '../../utils/Button/Button';

export const Form = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-top: 2.4rem;
  width: 50rem;

  @media only screen and (max-width: 600px) {
    width: unset;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 1.4rem;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`;

export const CancelButton = styled(TransparentButton)`
  width: 12rem;
`;

export const SubmitButton = styled(Button)`
  width: 12rem;
`;
