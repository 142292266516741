import { createAsyncThunk } from '@reduxjs/toolkit';
import AgencyService from '../../../api/services/AgencyService';

export const changePassword = createAsyncThunk('agency/changePassword', async requestData => {
  return await AgencyService.changePassword(requestData);
});

export const updateAgencyBasicInfo = createAsyncThunk('agency/updateAgencyBasicInfo', async requestData => {
  return await AgencyService.updateBasicInfo(requestData);
});

export const fetchInfluencerEarnings = createAsyncThunk('agency/fetchInfluencerEarnings', async requestData => {
  return await AgencyService.fetchInfluencerEarnings(requestData);
});

export const fetchTop10InfluencerEarnings = createAsyncThunk(
  'agency/fetchTop10InfluencerEarnings',
  async requestData => {
    return await AgencyService.fetchTop10InfluencerEarnings(requestData);
  },
);

export const fetchMonthlyInfluencersEarnings = createAsyncThunk('agency/fetchMonthlyInfluencersEarnings', async () => {
  return await AgencyService.fetchMonthlyInfluencersEarnings();
});

export const fetchInfluencerEarningsByMonth = createAsyncThunk(
  'agency/fetchInfluencerEarningsByMonth',
  async requestData => {
    return await AgencyService.fetchInfluencerEarningsByMonth(requestData);
  },
);

export const fetchInfluencerAnalytics = createAsyncThunk('agency/fetchInfluencerAnalytics', async requestData => {
  return await AgencyService.fetchInfluencerAnalytics(requestData);
});
